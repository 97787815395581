// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// @ts-ignore
import {Menu} from '../app/utility';

const SUPERADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.USERGROUPS,
  Menu.LESSONS,
  Menu.GLOSSARY,
  Menu.NEWS,
  Menu.PARTNER,
  Menu.STATS,
  Menu.SYSTEMTEXT,
  Menu.STATICCONTENT,
  Menu.LESSONPATHS,
  Menu.ASSIGNMENT,
  Menu.AUTHORS,
  Menu.PROVIDERS,
  Menu.SPONSORS,
  Menu.HERO,
  Menu.ROOMS,
];

const COMPANYADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.USERGROUPS,
  Menu.ASSIGNMENT,
];

export const environment: LcmsEnvironment = {
  production: false,
  e2e: false,

  statisticsKey: 'lz',

  server: {
    api     : 'https://api-lcms.e5-suite.de/backend',
    frontend: 'https://demo.lcms.e5-suite.de/',
    tenant  : 'demo'
  },

  roles: [
    SUPERADMIN,
    [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      18
    ],
    COMPANYADMIN
  ],
  difficulties : [
    {
      value : 1,
      label: 'Leicht'
    },
    {
      value : 2,
      label: 'Ausgewogen'
    },
    {
      value : 3,
      label: 'Schwer'
    }
  ],

  classifications : [
    {
      value : 'demo',
      label : 'Demo'
    },
    {
      value : 'basic',
      label: 'Basic',
    },
    {
      value : 'pro',
      label : 'Pro'
    }
  ],
  tenantConfig: {
    lesson: {
      sortLessons: true,
    },
    lessonDetails: {
      dueDate: true,
      mandatoryLessons: true,
      previewVideo: true,
      subtype: true,
      price: true,
      clearingnumber: true,
    },
    lessonPathDetails: {
      previewVideo: true,
      price: true,
      clearingnumber: true,
      chapters: {
        description: true,
        difficulty: true,
        duration: true,
        classification: true,
        sequence: true,
        url: true,
        certificateToggle: true,
      }
    },
    user: {
      groups: true,
      exportUserList: true,
      hasAdditionalFields: true,
      usernameRequired: true,
      demoUser: true,
    },
    company: {
      inviteCodes: true,
    },
    authors: {
      enabled: true,
    },
    providers: {
      enabled: true,
    },
    sponsors: {
      enabled: true,
    },
    certificate: {
      forLessons: true,
      forLessonPaths: true,
      description: true,
      subline: true,
      sponsor: true,
    },
    adaptiveLearning: {
      user: true
    }
  },
  trackingUrl: 'https://api-lcms.e5-suite.de/frontend/eagle5trackings/',
  lessonLink: ['https://api-lcms.e5-suite.de/wbts/demo/lesson_', '/eagle5_scorm_adapter.html?startpage='],
  createDefaults: {
    lessonPaths: {
      isprivate: true
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';
import {LcmsEnvironment} from "../../app/utility/models/lcms-environment.interface"; // Included with Angular CLI.
